<template>
  <BaseModal ref="modal" width="782" height="h-[440px]" title="پیش فاکتورها" icon="lab-profile" fill>
    <template #element="activatorProps">
      <div class="text-xs hover:text-blue-500 text-blue-dark-500 font-bold cursor-pointer">
        <div
          v-bind="activatorProps"
          class="w-10 h-10 border-[1.3px] text-blue-dark-400 rounded-lg grid place-items-center cursor-pointer relative"
          @click="getInvoicesList"
        >
          <div v-if="userInfo.proforma_invoices_count > 0" class=" absolute -top-2 -right-2">
            <span class="relative flex h-4 w-4">
              <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-300 opacity-75"></span>
              <span class="relative inline-flex rounded-full h-4 w-4 bg-blue-400">
                <div class="text-center mx-auto text-white">
                  {{ getPreInvoiceCount }}
                </div>
              </span>
            </span>
          </div>
          <BaseIcon icon="lab-profile" class=" text-blue-dark-400" />
        </div>
      </div>
    </template>
    <template #header>
    </template>
    <template #body>
      <div v-if="invoiceList.length && !loading" class="p-6">
        <template v-if="!smAndDown">
          <div v-for="invoice in invoiceList" :key="invoice" class="flex border-b mb-5 pb-5">
            <div
              class="w-full md:w-[123px] h-[95px] relative flex items-end justify-between p-2.5 rounded-lg bg-gray-200"
            >
              <div class="w-[115px] h-[90px]">
                <v-img :src="invoice.product.image_url" :lazy-src="invoice.product.image_url" alt="" class="w-full h-[90px] object-contain">
                  <template v-slot:placeholder>
                    <div class="d-flex align-center justify-center fill-height">
                      <v-progress-circular
                        color="grey-lighten-4"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                  </template>
                </v-img>
              </div>
              <img :src="invoice.factory_logo_url" alt="" class="w-[30px] h-[26px] object-cover" />
              <BaseIcon
                icon="favorite"
                class="inline-block md:hidden absolute top-2.5 left-2.5 !text-[32px] text-primary-500 cursor-pointer"
              />
            </div>
            <div class="flex flex-col justify-between w-full">
              <div class="flex justify-between items-center gap-2">
                <div class="mr-3 text-sm font-bold flex items-center">
                  <BaseIcon icon="category" />
                  <div class="mr-2">
                    <span class="font-bold text-blue-dark-500">
                      {{ invoice.product.title }} - 
                    </span>
                    {{ String(parseFloat(invoice.tonage)) }} تن
                  </div>
                </div>
                <div class="text-sm text-blue-dark-100">
                  <span class="ml-2">شماره</span>
                  {{ invoice.id }}
                </div>
              </div>
              <div class="mr-3 text-blue-dark-300">
                <span class=" text-sm">
                  <BaseIcon icon="universal-currency" class="align-middle text-xl" />
                  مبلغ فاکتور
                </span>
                <span class=" text-blue-dark-500 text-xs mr-2">                 
                  {{ new Intl.NumberFormat().format(invoice.tonage * invoice.price_per_ton) }} ریال
                </span>
              </div>
              <div class="mr-3 text-blue-dark-300">
                <div class="flex justify-between">
                  <div class=" text-sm">
                    <div v-if="invoice.status != 1">
                      <BaseIcon icon="info" class=" text-secondry-600 align-middle text-xl" fill />
                      به دلیل تغییر قیمت این پیش فاکتور دیگر اعتبار ندارد.
                    </div>
                    <div v-else>
                      <BaseIcon icon="event" class="text-blue-dark-300 align-top text-xl" />
                      <div class="mr-1 d-ltr inline-block">
                        {{ dayjs(invoice.date_paid || invoice.created_at).calendar("jalali").locale("fa").format("YYYY/MM/DD HH:MM") }}
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="text-primary-500 text-sm font-bold cursor-pointer" @click="removeInvoiceHandler(invoice.id)">
                      حذف
                    </div>
                    <div v-if="invoice.status == 1" class="text-primary-500 text-sm font-bold cursor-pointer border-r mr-4 pr-4">
                      <BaseLoading v-if="downloadLoading && selectedInvoice == invoice.id" />
                      <div v-else @click="downloadPreInvoce(invoice.id)">
                        دانلود
                      </div>
                    </div>
                    <div v-if="invoice.status == 1" class="text-primary-500 text-sm font-bold cursor-pointer pr-4 border-r mr-4" @click="modal.closeDialog()">
                      <router-link :to="`/store/confirm/${invoice.id}`">
                        ادامه خرید
                        <BaseIcon icon="arrow-circle-left" class="text-primary-500 align-top text-2xl" />
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="invoice in invoiceList" :key="invoice" class="border-b pb-6 mb-6">
            <div
              class="w-full md:w-[123px] h-[95px] relative flex items-end justify-between p-2.5 rounded-lg bg-gray-200"
            >
              <div class="w-[115px] h-[90px]">
                <img :src="invoice.product.image_url" alt="" class="w-full h-[90px] object-contain" />
              </div>
              <img :src="invoice.factory_logo_url" alt="" class="w-[30px] h-[26px] object-cover" />
            </div>
            <div class="py-3">
              <div class="flex items-center">
                <BaseIcon icon="category" class=" text-blue-dark-500 text-xl align-middle" />
                <div class="font-bold mr-2">
                  <span class="font-bold text-blue-dark-500">
                    {{ invoice.product.title }} -
                  </span>
                  <span>
                    {{ String(parseFloat(invoice.tonage)) }} تن
                  </span>
                </div>
              </div>
              <div class="mt-3">
                <span class="text-xs text-blue-dark-200"> پیش فاکتور {{ invoice.id }}</span>
              </div>
              <div class="mt-2">
                <div class="text-sm mt-1 text-blue-dark-500">
                  <BaseIcon icon="universal-currency" class="align-middle text-xl ml-2" />
                  {{ new Intl.NumberFormat().format(invoice.tonage * invoice.price_per_ton) }} ریال
                </div>
              </div>
              <div v-if="invoice.status != 1" class="text-sm mt-3">
                <BaseIcon icon="info" class=" text-secondry-600 align-middle text-xl" fill />
                <span class="text-xs text-blue-dark-300 mr-2">به دلیل تغییر قیمت این پیش فاکتور دیگر اعتبار ندارد.</span>
              </div>
              <div v-else class="text-xs text-blue-dark-500 mt-4 d-ltr w-24">
                {{ dayjs(invoice.date_paid || invoice.created_at).calendar("jalali").locale("fa").format("YYYY/MM/DD HH:MM") }}
              </div>
              <div class="flex mt-6 justify-between" :class="invoice.status == 1 ? 'justify-between' : 'justify-end'">
                <div class="text-primary-500 text-sm font-bold cursor-pointer" @click="removeInvoiceHandler(invoice.id)">
                  <BaseIcon icon="trash" class=" text-primary-500 align-middle text-xl ml-2" />
                  حذف
                </div>
                <div v-if="invoice.status == 1" class="w-[1px] bg-blue-dark-50"></div>
                <div v-if="invoice.status == 1" class="text-primary-500 text-sm font-bold cursor-pointer">
                  <BaseLoading v-if="downloadLoading && selectedInvoice == invoice.id" />
                  <div v-else @click="downloadPreInvoce(invoice.id)">
                    <BaseIcon icon="download-offline" class=" text-primary-500 align-middle text-xl ml-2" />
                    دانلود
                  </div>
                </div>
                <div v-if="invoice.status == 1" class="w-[1px] bg-blue-dark-50"></div>
                <div v-if="invoice.status == 1" class="text-primary-500 text-sm font-bold cursor-pointer pr-2 mr-2" @click="modal.closeDialog()">
                  <router-link :to="`/store/confirm/${invoice.id}`">
                    ادامه خرید
                    <BaseIcon icon="arrow-circle-left" class=" text-primary-500 align-middle text-xl" />
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <EmptyBox v-else title="فاکتوری به ثبت نرسیده است" :loading="loading" />
    </template>
    <template #footer="isActive">
      <div class="p-6 flex flex-row-reverse justify-between items-center">
        <v-btn class="  hover:bg-blue-500 border border-primary-500 hover:text-white text-primary-500" @click="isActive.value = false">
          <span class="text-sm">
            بستن
          </span>
        </v-btn>
        <div class="text-sm text-red-600 cursor-pointer" @click="removeAllHandler">
          <BaseIcon icon="trash" class="align-middle ml-2" />
          حذف تمام موارد
        </div>
      </div>
    </template>
  </BaseModal>
  <v-dialog
    v-model="removeInvoice"
    width="auto"
  >
    <div class="bg-white w-72 rounded-lg p-4 text-center">
      <BaseIcon icon="warning" class="text-red-100 text-[40px] mb-2" />
      <div class="text-sm text-blue-dark-500 font-bold">
        فاکتور حذف شده قابل بازگردانی نمی باشد
      </div>
      <div class="border-t-[1px] my-3"></div>
      <div class="text-xs text-blue-dark-300 text-right mr-4">
        با حذف این پیش فاکتور تمامی اطلاعات این پیش فاکتور حذف خواهد شد .
      </div>
      <div class="mt-8 flex gap-2">
        <v-btn class="w-1/2 text-primary-500 hover:text-white hover:bg-blue-500 border border-primary-500" :loading="loading" @click="removeInvoice = false">
          <span class="text-sm">
            انصراف
          </span>
        </v-btn>
        <v-btn class="w-1/2 text-white bg-blue-500 border border-primary-500" :loading="loading" @click="removeSelectedInvoice">
          <BaseIcon icon="check-circle" class="ml-2" />
          <span class="text-sm">
            تایید
          </span>
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script lang="ts" setup>
import { apiDownloadPreInvoice, apiGetPreInvoicesList, apiRemovePreInvoice } from '@/services/store/store';
import BaseModal from '../base/BaseModal.vue';
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router';
import EmptyBox from "@/components/base/BaseEmptyData.vue";
import { useDisplay } from 'vuetify';
import { toast, type ToastOptions } from "vue3-toastify";
import dayjs from "dayjs";
import { useAuthStore } from '@/stores/authStore';
import { apiGetUserInfo } from '@/services/authentication/authentication';
import BaseLoading from "@/components/base/BaseLoading.vue";
import { saveAs } from 'file-saver';

const userInfo = useAuthStore().userInfo
const { smAndDown, platform } = useDisplay()
const invoiceList: any = ref([])
const router = useRouter()
const modal = ref()
const loading = ref(false)
const removeInvoice = ref(false)
const selectedInvoice = ref()
const downloadLoading = ref()
const getInvoicesList = async () => {
  loading.value = true
  const { data } = await apiGetPreInvoicesList()
  const list: any = data
  invoiceList.value = list.data
  loading.value = false
}
const removeInvoiceHandler = async (id: any) => {
  removeInvoice.value = true
  selectedInvoice.value = id
}
const removeSelectedInvoice = async () => {
  loading.value = true
  try {
    const body = {
      id: selectedInvoice.value
    }
    const data = await apiRemovePreInvoice(body)
    toast(data.data.message, {
      type: "success",
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
    loading.value = false
    getInvoicesList()
    useAuthStore().userInfo = (await apiGetUserInfo()).data.data;
  } catch (error) {
    loading.value = false
    console.log(error)
  } finally {
    removeInvoice.value = false
  }
}
const removeAllHandler = async () => {
  loading.value = true
  try {
    const body = {
      all: 1
    }
    const data = await apiRemovePreInvoice(body)
    toast(data.data.message, {
      type: "success",
      position: toast.POSITION.TOP_RIGHT,
    } as ToastOptions);
    loading.value = false
    getInvoicesList()
  } catch (error) {
    loading.value = false
    console.log(error)
  } finally {
    removeInvoice.value = false
  }
}
const getPreInvoiceCount = computed(() => {
  return  useAuthStore().userInfo.proforma_invoices_count
})
const downloadPreInvoce = async (id: any) => {
  downloadLoading.value = true
  try {
    const { data } = await apiDownloadPreInvoice(id)
    if (!smAndDown.value) {      
      const link = document.createElement('a')
      link.href = data.path
      link.download = data.origin_name
      link.target = '_blank'
      link.click()
      window.URL.revokeObjectURL(data.path)
    } else if (platform.value.ios) {
      window.location.replace(data.path)
    } else {
      saveAs(data.path, data.origin_name)
    }
  } catch (error) {
    console.log(error); 
  } finally {
    downloadLoading.value = false
  }
}
</script>